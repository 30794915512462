<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :width="480"
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="流量详情"
      @ok="handleSubmit"
    >
      <a-row>
        <a-form
          class="simiot-compact-form"
          :form="form"
          @submit="handleSubmit"
        >
          <a-col :span="24">
            <a-form-item label="时间范围">
              <a-range-picker
                @panelChange="handleTimePanelChange"
                @change="handleTimePanelChange"
                :value="monthRange"
                format="YYYY-MM"
                :mode="timeMode"
              />
            </a-form-item>
          </a-col>
        </a-form>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'BatchOperationSimCardsFlow',
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'sim_cards_flow' }),
      timeMode: ['month', 'month'],
      monthRange: [],
      queryParam: {},
      submitting: false
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    handleTimePanelChange(value) {
      if (value.length === 2) {
        this.monthRange = value
        this.queryParam.startMonth = value[0].format('YYYYMM')
        this.queryParam.endMonth = value[1].format('YYYYMM')
      } else {
        this.monthRange = []
        this.queryParam.startMonth = ''
        this.queryParam.endMonth = ''
      }
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (this.monthRange.length !== 2) {
          this.$warning({
            title: '请选择时间范围',
            content: ''
          })

          return
        }

        if (!err) {
          this.submitting = true

          // 关闭模态框
          this.isShow = false
          // 告知父组件已完成
          this.$emit('completed', this.queryParam)
        }
      })
    }
  }
}
</script>
